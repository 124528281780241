import { navigate } from "gatsby-link";
import React from "react";
import PropTypes from "prop-types"
import {Question} from "../components/svg/icons";

const Showcase = ({title, thumbnail, name, description, slug}) => {

    return (
        <div 
            className="cursor-pointer flex flex-col bg-white shadow-lg w-72 md:w-60 sm:w-64 xs:w-64 transform hover:scale-105 border-4 border-orange"
            onClick={slug ? ()=>navigate("/reviews/".concat(slug)) : null}
            aria-hidden="true"
        >
            <div className="flex bg-orange text-white font-black justify-center py-2">
                {title}
            </div>
            <div className="flex flex-col p-4">
                <div className="flex justify-center">
                    {thumbnail ? 
                        (<img
                            alt={title} 
                            src={thumbnail} 
                            className="h-40 m-2"
                        />)
                        : 
                        (<Question className="h-40 m-2 text-black text-opacity-50"/>)
                    }

                </div>
                <div className="flex justify-center text-blue font-bold text-xl md:text-lg my-2">
                    {name}
                </div>
                <div className="text-black text-opacity-75 text-md md:text-base h-22 md:h-28">
                    {description}
                </div>
            </div>
        </div>
    )
}

Showcase.propTypes = {
    title: PropTypes.string,
    thumbnail: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
}
  
Showcase.defaultProps = {
    title: '',
    thumbnail: null,
    name: '',
    description: '',
    slug: null,
}

export default Showcase