import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Showcase from "../components/showcase";
import { Map } from "../components/svg/map";
import { BannerLong } from "../components/svg/banner";
import { navigate } from "gatsby-link";
import { Search } from 'react-feather';
import { Send } from "../components/svg/icons";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="relative flex flex-col">
      
      <div className="container flex flex-col pl-4 mx-auto z-10 my-10 h-96 sm:h-48 xs:h-full justify-center">
        <div className="flex w-1/2 sm:w-full xs:w-full text-7xl sm:text-5xl xs:text-4xl text-black justify-center xs:justify-start pb-4 xs:pb-1">
          Hey There!
        </div>
        <div className="w-1/2 text-2xl md:text-xl sm:text-base xs:text-base text-black text-opacity-75">
          Welcome to PokePossi, Seattle's favorite poké blog.
        </div>

        <div className="w-1/2 sm:w-full xs:w-full flex sm:justify-center xs:justify-center justify-center">
          <button 
            class="cursor-pointer flex mt-4 justify-center shadow-lg border-2 text-white bg-orange border-orange border-opacity-100 p-1 focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange focus:bg-white focus:text-orange hover:bg-white hover:text-orange sm:text-sm"
            onClick={()=>navigate("/search")}
          >
            <span class="flex items-center">
              <Search className="h-4 mr-1 -ml-1"/>
              <span class="block truncate font-bold text-md">
                Find Your Spot
              </span>
            </span>
          </button>
        </div>
      </div>
      <div className="flex w-full absolute justify-end py-8 z-0">
        <BannerLong className="h-96 sm:h-40 xs:h-36 opacity-50"/>
      </div>

      <div className="bg-blue bg-opacity-25 py-8 xs:py-4">
        <div className="container flex flex-col mx-auto px-4 justify-between">

          <div className="flex text-6xl sm:text-3xl xs:text-3xl text-black pb-6 xs:pb-1 justify-center">
              Reviews Near You
          </div>

          <div className="flex flex-row sm:flex-col xs:flex-col">

            <div className="flex border-4 border-green px-4 w-1/2 sm:w-full xs:w-full shadow-lg xs:mt-2">
              <Map className="w-full h-full"/>
            </div>

            <div className="flex flex-col justify-center pl-8 sm:pl-0 xs:pl-0 sm:mt-4 xs:mt-4 sm:w-full xs:w-full w-1/2">

              <div className="flex text-2xl md:text-xl sm:text-base xs:text-base text-black text-opacity-75">
                We are on a mission to search far and wide (In Seattle atleast ¯\_(ツ)_/¯) to find and review all 
                of the eligible poké bars for your poké eating pleasure. Here is our VERY extensive and growing map 
                of poké bars we have reviewed and rated for you, our adoring fans.
                <br></br>
                <br></br>
                Do you not see the place you're looking for? Let us know we need to step it up.
              </div>

              <div className="flex justify-center mb-2">
                <button 
                  class="cursor-pointer flex mt-4 justify-center shadow-lg border-2 text-white bg-orange border-orange border-opacity-100 p-1 focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange focus:bg-white focus:text-orange hover:bg-white hover:text-orange sm:text-sm"
                  onClick={()=>navigate("/contact")}
                >
                  <span class="flex items-center">
                    <Send className="h-4 transform rotate-90 mr-2"/>
                    <span class="block truncate font-bold text-md">
                      Message Us
                    </span>
                  </span>
                </button>
              </div>

            </div>
          </div>

 

        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="flex text-6xl sm:text-3xl xs:text-3xl text-black pb-8 xs:pb-4 justify-center">
          Restaurant Showcase
        </div>
        <div className="flex flex-row px-2 sm:flex-col xs:flex-col justify-center">
          <div className="flex justify-center items-center m-4 md:m-1">
            <Showcase title="Up Next.." name="Destination Unknown" description="Lookout Ballard, there's a new review coming to town. How will it stack up against Poke Lover? Stay tuned to find out."/>
          </div>
          <div className="flex sm:order-first xs:order-first justify-center items-center m-4 md:m-2 transform scale-105 sm:scale-100 xs:scale-100">
            <Showcase title="Top Pick" thumbnail={require(`../images/fob_poke_bar/logo.png`)} name="FOB Poke Bar" description="The best of the best. The big Kahuna. Top notch in more ways than one, just do yourself a favor and DO NOT GET THE LARGE." slug="fob_poke_bar"/>
          </div>
          <div className="flex justify-center items-center m-4 md:m-1">
            <Showcase title="Recently Reviewed" thumbnail={require(`../images/hiroshi's_poke/logo.png`)} name="Hiroshi's Poke" description="Worth a try hole in the wall. An obvious stop for the nearby college students, they got the macaroni salad hook-up!" slug="hiroshi's_poke"/>
          </div>
        </div>
      </div>

    </div>
  </Layout>
)

export default IndexPage
