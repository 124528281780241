import React from "react";

export const BannerSmall = ({className=""}) => (
    <svg className={className} viewBox="0 0 2153 1257" version="1.1" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2">
        <g transform="matrix(1,0,0,1,0,-1372.8)">
            <g id="flat" transform="matrix(0.60484,0,0,0.704924,733.49,1121.76)">
                <g clip-path="url(#_clip1)">
                    <g id="banner" transform="matrix(1.65333,0,0,1.41851,-1582.77,-369.869)">
                        <g transform="matrix(1,0,0,1,0.299559,0.123195)">
                            <path d="M2375.8,711.524L2375.8,1768.75C2375.8,1768.75 2071.75,1531.26 1673.98,1638.43C1276.22,1745.6 1179.05,1561.79 1040.69,1408.57C902.32,1255.35 772.651,1309.63 666.271,1358.1C559.891,1406.56 -117.185,1473.96 442.61,914.16C1002.41,354.365 1232.27,640.097 1358.56,707.806C1484.86,775.515 1453.59,822.777 1661.34,809.943C1869.09,797.109 1956.83,573.906 2375.8,711.524Z" fill="rgb(138,182,207)"/>
                        </g>
                        <g transform="matrix(1,0,0,1,0.299559,0.123195)">
                            <g transform="matrix(1,0,0,1,-1213,356)">
                                <path d="M3028.25,1257.2C3016.68,1185.13 3031.93,1090.21 3142.31,1002.88C3358.87,831.551 3152.34,685.722 3143.11,606.423C3133.89,527.123 3152.21,361.786 3274.39,253.371C3396.58,144.956 3346.45,331.075 3346.45,331.075C3346.45,331.075 3300.83,579.979 3437.59,646.332C3574.34,712.685 3682,984.983 3445.64,1213.08C3414.04,1243.58 3393.64,1274.8 3382.02,1306.06C3331.44,1287.13 3271.93,1270.44 3205.13,1261.36C3208.07,1209.25 3231.23,1158.23 3290.23,1117.29C3357.78,1070.43 3394.38,1018.22 3411.03,966.606C3450,845.827 3379.24,727.424 3333.66,683.458C3303.31,654.186 3273.78,625.451 3259.76,580.694C3245.63,535.59 3247.33,474.567 3278.21,381.217C3302.35,296.121 3292.28,305.603 3260.41,375.329C3227.77,474.017 3226.93,538.617 3241.87,586.3C3256.92,634.329 3288.07,665.542 3320.64,696.954C3363.15,737.948 3429.52,848.235 3393.19,960.849C3377.57,1009.26 3342.89,1057.94 3279.55,1101.89C3215.13,1146.58 3189.62,1202.22 3186.26,1259.02C3137.11,1253.52 3084.24,1252.24 3028.25,1257.2Z" fill="rgb(69,132,90)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-1213,356)">
                                <path d="M2848.55,1292.1C2849.09,1259.59 2858.91,1222.33 2883.99,1180.67C2986.13,1010.99 2810.67,965.207 2784.47,914.296C2758.26,863.385 2728.83,747.76 2783.5,644.138C2838.17,540.516 2851.49,678.12 2851.49,678.12C2851.49,678.12 2883.68,856.738 2992.24,866.771C3100.8,876.803 3241.8,1032.44 3140.68,1245.23C3139.11,1248.53 3137.65,1251.8 3136.29,1255.02C3091.38,1252.86 3043.8,1254.4 2993.97,1260.98C2996.35,1245.89 3001.52,1230.68 3010.19,1215.58C3051.33,1143.91 3061.93,1086.11 3047.59,1039.01C3033.18,991.653 2993.42,954.438 2931.56,924.824C2886.43,903.221 2859.17,878.085 2843.1,852.15C2819.11,813.435 2820.15,773.249 2822.23,742.027C2819.46,687.548 2813.08,689.41 2803.52,740.785C2801.23,775.195 2800.72,819.359 2827.16,862.027C2844.71,890.347 2874.18,918.147 2923.46,941.736C2979.61,968.614 3016.57,1001.49 3029.65,1044.47C3042.81,1087.7 3031.69,1140.46 2993.93,1206.25C2982.94,1225.39 2976.86,1244.69 2974.61,1263.77C2946.09,1268.24 2916.86,1274.38 2886.98,1282.43C2873.86,1285.96 2861.06,1289.18 2848.55,1292.1Z" fill="rgb(95,156,113)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,167.438,-1380.52)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.626741,0,0,0.626741,397.328,-2.88787)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.626741,0,0,0.626741,546.739,-204.906)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,716.465,-875.171)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.770694,0,0,0.770694,1531.33,-706.673)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.654277,0,0,0.654277,1899.64,224.953)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                        </g>
                        <g transform="matrix(-2.14034,-5.55112e-16,-5.55112e-16,2.14034,2874.01,-182.874)">
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(-0.502827,-0.134732,0.134732,-0.502827,1170.05,755.08)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)" stroke="rgb(51,51,51)" stroke-width="16.83px"/>
                            </g>
                            <g transform="matrix(1,0,0,1,21,0)">
                                <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1.29484,-4.93038e-32,4.93038e-32,1.29484,-197.749,-148.87)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(-0.734498,0.196808,-0.196808,-0.734498,1361.72,628.469)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)" stroke="rgb(51,51,51)" stroke-width="11.52px"/>
                            </g>
                            <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                            <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const BannerLong = ({className=""}) => (
    <svg className={className} viewBox="0 0 2571 1257" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2">
        <g transform="matrix(1,0,0,1,0,-1380.67)">
            <g id="flat" transform="matrix(0.722368,0,0,0.704924,876.016,1129.63)">
                <g clip-path="url(#_clip1)">
                    <g id="banner" transform="matrix(1.38434,0,0,1.41851,-943.811,-369.869)">
                        <g id="bg" transform="matrix(1,0,0,1,0.299559,0.123195)">
                            <path d="M2375.8,711.524L2375.8,1768.75C2375.8,1768.75 2071.75,1531.26 1673.98,1638.43C1276.22,1745.6 937.052,1561.79 798.686,1408.57C660.32,1255.35 337.874,1340.73 222.271,1358.1C58.213,1382.75 -478.486,1330.56 -1.39,914.16C595.061,393.595 990.274,640.097 1116.56,707.806C1242.86,775.515 1453.59,822.777 1661.34,809.943C1869.09,797.109 1956.83,573.906 2375.8,711.524Z" fill="rgb(138,182,207)"/>
                        </g>
                        <g id="extras" transform="matrix(1,0,0,1,0.299559,0.123195)">
                            <g transform="matrix(1,0,0,1,-1213,356)">
                                <path d="M3028.25,1257.2C3016.68,1185.13 3031.93,1090.21 3142.31,1002.88C3358.87,831.551 3152.34,685.722 3143.11,606.423C3133.89,527.123 3152.21,361.786 3274.39,253.371C3396.58,144.956 3346.45,331.075 3346.45,331.075C3346.45,331.075 3300.83,579.979 3437.59,646.332C3574.34,712.685 3682,984.983 3445.64,1213.08C3414.04,1243.58 3393.64,1274.8 3382.02,1306.06C3331.44,1287.13 3271.93,1270.44 3205.13,1261.36C3208.07,1209.25 3231.23,1158.23 3290.23,1117.29C3357.78,1070.43 3394.38,1018.22 3411.03,966.606C3450,845.827 3379.24,727.424 3333.66,683.458C3303.31,654.186 3273.78,625.451 3259.76,580.694C3245.63,535.59 3247.33,474.567 3278.21,381.217C3302.35,296.121 3292.28,305.603 3260.41,375.329C3227.77,474.017 3226.93,538.617 3241.87,586.3C3256.92,634.329 3288.07,665.542 3320.64,696.954C3363.15,737.948 3429.52,848.235 3393.19,960.849C3377.57,1009.26 3342.89,1057.94 3279.55,1101.89C3215.13,1146.58 3189.62,1202.22 3186.26,1259.02C3137.11,1253.52 3084.24,1252.24 3028.25,1257.2Z" fill="rgb(69,132,90)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-1213,356)">
                                <path d="M2848.55,1292.1C2849.09,1259.59 2858.91,1222.33 2883.99,1180.67C2986.13,1010.99 2810.67,965.207 2784.47,914.296C2758.26,863.385 2728.83,747.76 2783.5,644.138C2838.17,540.516 2851.49,678.12 2851.49,678.12C2851.49,678.12 2883.68,856.738 2992.24,866.771C3100.8,876.803 3241.8,1032.44 3140.68,1245.23C3139.11,1248.53 3137.65,1251.8 3136.29,1255.02C3091.38,1252.86 3043.8,1254.4 2993.97,1260.98C2996.35,1245.89 3001.52,1230.68 3010.19,1215.58C3051.33,1143.91 3061.93,1086.11 3047.59,1039.01C3033.18,991.653 2993.42,954.438 2931.56,924.824C2886.43,903.221 2859.17,878.085 2843.1,852.15C2819.11,813.435 2820.15,773.249 2822.23,742.027C2819.46,687.548 2813.08,689.41 2803.52,740.785C2801.23,775.195 2800.72,819.359 2827.16,862.027C2844.71,890.347 2874.18,918.147 2923.46,941.736C2979.61,968.614 3016.57,1001.49 3029.65,1044.47C3042.81,1087.7 3031.69,1140.46 2993.93,1206.25C2982.94,1225.39 2976.86,1244.69 2974.61,1263.77C2946.09,1268.24 2916.86,1274.38 2886.98,1282.43C2873.86,1285.96 2861.06,1289.18 2848.55,1292.1Z" fill="rgb(95,156,113)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,167.438,-1408.53)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.626741,0,0,0.626741,333.939,27.1256)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.626741,0,0,0.626741,546.739,-204.906)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,716.465,-875.171)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.770694,0,0,0.770694,1531.33,-706.673)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.654277,0,0,0.654277,1899.64,224.953)">
                                <circle cx="503.998" cy="2068.02" r="115.306" fill="rgb(212,238,252)"/>
                            </g>
                        </g>
                        <g transform="matrix(-2.14034,-5.55112e-16,-5.55112e-16,2.14034,2931.01,-182.874)">
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(-0.502827,-0.134732,0.134732,-0.502827,1170.05,755.08)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)" stroke="rgb(51,51,51)" stroke-width="16.83px"/>
                            </g>
                            <g transform="matrix(1,0,0,1,21,0)">
                                <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1.29484,-4.93038e-32,4.93038e-32,1.29484,-197.749,-148.87)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(-0.734498,0.196808,-0.196808,-0.734498,1361.72,628.469)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)" stroke="rgb(51,51,51)" stroke-width="11.52px"/>
                            </g>
                            <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                            <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const BannerXl = ({className=""}) => (
    <svg className={className} viewBox="0 0 3210 1696" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2">
        <g transform="matrix(1,0,0,1,0,-1380.67)">
            <g id="flat" transform="matrix(0.901948,0,0,0.950722,1093.79,1042.1)">
                <g clip-path="url(#_clip1)">
                    <g id="banner" transform="matrix(1.10874,0,0,1.05184,-296.175,-155.539)">
                        <g transform="matrix(1.00822,0,0,1.00006,-731.09,-868.956)">
                            <g id="bg">
                                <path d="M3088.37,3050.51C3088.37,3050.51 2748.05,2628.21 2243.48,2779.9C1738.91,2931.59 1352.31,2800.88 1084.05,2545.52C815.782,2290.16 735.073,2276.76 447.701,2329.9C160.33,2383.03 -437.501,2303.55 163.538,1720.46C764.576,1137.37 1324.61,1409.81 1437.14,1481.84C1549.66,1553.87 1869.45,1734.15 2101.95,1581.45C2334.45,1428.74 2806.94,1296.82 3083.87,1649.03"  fill="rgb(138,182,207)"/>
                            </g>
                        </g>
                        <g id="extras" transform="matrix(1,0,0,1,0.299559,0.123195)">
                            <g transform="matrix(0.9967,0.0811795,-0.0811697,0.9967,-1173.57,376.053)">
                                <path d="M3028.25,1257.2C3016.68,1185.13 3031.93,1090.21 3142.31,1002.88C3358.87,831.551 3152.34,685.722 3143.11,606.423C3133.89,527.123 3152.21,361.786 3274.39,253.371C3396.58,144.956 3346.45,331.075 3346.45,331.075C3346.45,331.075 3300.83,579.979 3437.59,646.332C3574.34,712.685 3682,984.983 3445.64,1213.08C3414.04,1243.58 3393.64,1274.8 3382.02,1306.06C3331.44,1287.13 3271.93,1270.44 3205.13,1261.36C3208.07,1209.25 3231.23,1158.23 3290.23,1117.29C3357.78,1070.43 3394.38,1018.22 3411.03,966.606C3450,845.827 3379.24,727.424 3333.66,683.458C3303.31,654.186 3273.78,625.451 3259.76,580.694C3245.63,535.59 3247.33,474.567 3278.21,381.217C3302.35,296.121 3292.28,305.603 3260.41,375.329C3227.77,474.017 3226.93,538.617 3241.87,586.3C3256.92,634.329 3288.07,665.542 3320.64,696.954C3363.15,737.948 3429.52,848.235 3393.19,960.849C3377.57,1009.26 3342.89,1057.94 3279.55,1101.89C3215.13,1146.58 3189.62,1202.22 3186.26,1259.02C3137.11,1253.52 3084.24,1252.24 3028.25,1257.2Z"  fill="rgb(69,132,90)"/>
                            </g>
                            <g transform="matrix(0.9967,0.0811795,-0.0811697,0.9967,-1173.57,376.053)">
                                <path d="M2848.55,1292.1C2849.09,1259.59 2858.91,1222.33 2883.99,1180.67C2986.13,1010.99 2810.67,965.207 2784.47,914.296C2758.26,863.385 2728.83,747.76 2783.5,644.138C2838.17,540.516 2851.49,678.12 2851.49,678.12C2851.49,678.12 2883.68,856.738 2992.24,866.771C3100.8,876.803 3241.8,1032.44 3140.68,1245.23C3139.11,1248.53 3137.65,1251.8 3136.29,1255.02C3091.38,1252.86 3043.8,1254.4 2993.97,1260.98C2996.35,1245.89 3001.52,1230.68 3010.19,1215.58C3051.33,1143.91 3061.93,1086.11 3047.59,1039.01C3033.18,991.653 2993.42,954.438 2931.56,924.824C2886.43,903.221 2859.17,878.085 2843.1,852.15C2819.11,813.435 2820.15,773.249 2822.23,742.027C2819.46,687.548 2813.08,689.41 2803.52,740.785C2801.23,775.195 2800.72,819.359 2827.16,862.027C2844.71,890.347 2874.18,918.147 2923.46,941.736C2979.61,968.614 3016.57,1001.49 3029.65,1044.47C3042.81,1087.7 3031.69,1140.46 2993.93,1206.25C2982.94,1225.39 2976.86,1244.69 2974.61,1263.77C2946.09,1268.24 2916.86,1274.38 2886.98,1282.43C2873.86,1285.96 2861.06,1289.18 2848.55,1292.1Z"  fill="rgb(95,156,113)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,167.438,-1408.53)">
                                <circle cx="503.998" cy="2068.02" r="115.306"  fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,429.917,-427.094)">
                                <circle cx="503.998" cy="2068.02" r="115.306"  fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.626741,0,0,0.626741,333.939,27.1256)">
                                <circle cx="503.998" cy="2068.02" r="115.306"  fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.626741,0,0,0.626741,1067.19,634.519)">
                                <circle cx="503.998" cy="2068.02" r="115.306"  fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.626741,0,0,0.626741,546.739,-204.906)">
                                <circle cx="503.998" cy="2068.02" r="115.306"  fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,728.175,-853.117)">
                                <circle cx="503.998" cy="2068.02" r="115.306"  fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.770694,0,0,0.770694,1580.6,-771.889)">
                                <circle cx="503.998" cy="2068.02" r="115.306"  fill="rgb(212,238,252)"/>
                            </g>
                            <g transform="matrix(0.654277,0,0,0.654277,1946.59,378.509)">
                                <circle cx="503.998" cy="2068.02" r="115.306"  fill="rgb(212,238,252)"/>
                            </g>
                        </g>
                        <g transform="matrix(-2.14034,-5.55112e-16,-5.55112e-16,2.14034,2931.01,-182.874)">
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27"  fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z"  fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78"  fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z"  fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33"  fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z"  fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39"  fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z"  fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(-0.502827,-0.134732,0.134732,-0.502827,1170.05,755.08)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z"  fill="rgb(51,51,51)" stroke="rgb(51,51,51)" stroke-width="16.83px"/>
                            </g>
                            <g transform="matrix(1,0,0,1,21,0)">
                                <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93"  fill="rgb(255,166,133)"/>
                            </g>
                            <g transform="matrix(1.29484,-4.93038e-32,4.93038e-32,1.29484,-197.749,-148.87)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z"  fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(-0.734498,0.196808,-0.196808,-0.734498,1361.72,628.469)">
                                <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z"  fill="rgb(51,51,51)" stroke="rgb(51,51,51)" stroke-width="11.52px"/>
                            </g>
                            <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838"  fill="rgb(255,166,133)"/>
                            <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z"  fill="rgb(51,51,51)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);